import React from "react";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

function Policy() {

    return(
        <>
            <PrivacyPolicy />
        </>
    );
}

export default Policy;