import React from "react";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";

function Terms() {

    return(
        <>
            <TermsAndConditions />
        </>
    );
}

export default Terms;